<template>
    <div class="company-contain">
        <div class="table-box">
            <el-table
                    :data="taskList"
                    class="college-table"
                    style="width: 100%;overflow: hidden;min-height:560px"
                    size="medium"
                    :header-cell-style="{fontWeight: 'Regular', color: '#333', background: '#fff',}"
                    :cell-style="{fontSize: '12px',color: '#333'}"
            >
                <el-table-column
                        prop="task_id"
                        label="视频内容"
                        width="300"
                        align="left"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.task_id}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="task_title" label="播主" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.task_title}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="点赞总数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">¥{{scope.row.task_money}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="评论总数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.apply}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="转发总数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title" v-if="scope.row.num==0">无限制</span>
                        <span class="student-name-title" v-else>{{scope.row.num}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="250" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" circle @click="get1" size="small">
                            <i class="iconfont">&#xe6a1;</i>
                        </el-button>
                        <el-button type="success" circle size="small" @click="get1">
                            <i class="iconfont">&#xe647;</i>
                        </el-button>
                        <el-button type="danger" circle size="small">
                            <i class="iconfont">&#xe63a;</i>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
                class="pages-center"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    export default {
        name: 'HotVideo',
        data() {
            return {
                isAll: 0,
                radio: "",
                typeLsit: [], //分类列表
                isFilt: 0,
                isFilt1: 0,
                task_condition: "",
                filterList: ["全部", "进行中", "已截止"],
                filterList1: ["全部", "需报名", "无需报名"],
                taskList: [],//任务列表
                //分页
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //编辑获得的数据
                editForm: {
                    id: '',
                    tasktype: "",//任务分类
                    title: "", //标题
                    describe: "", //描述
                    cover: "", //封面
                    time: "", //截至时间
                    Islimit: "",//是否限制人数 0否 1是
                    limitnum: "",//限制人数
                    taskmoney: "",//任务金额
                    apply: "",//是否报名 0否 1是
                    file: [] //附件
                }
            }
        },
        mounted() {
            this.getTaskType();
            this.getAllClass();
        },
        methods: {
            isAllS(id) {
                this.isAll = id;
            },
            isFilter(id) {
                this.isFilt = id;
            },
            isFilter1(id) {
                this.isFilt1 = id;
                if (this.isFilt1 == 1) {
                    this.task_condition = 0
                } else if (this.isFilt1 == 2) {
                    this.task_condition = 1
                } else if (this.isFilt1 == 0) {
                    this.task_condition = ""
                }
            },
            //全部
            allClas() {
                this.isAll = 0;
            },
            getTaskType() {
                let params = {};
                this.$httpcompany.axiosGetBy(this.$api.taskTypeList, params, res => {
                    this.typeLsit = res;
                });
            },
            get1() {

            },
            // 获取全部任务列表
            getAllClass() {
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum, //页面显示条数
                    type_id: this.isAll,//任务类型
                    task_state: this.isFilt,//任务状态 1 进行中 2 已截止
                    task_condition: this.task_condition//任务条件是否报名
                };
                if (this.isAll == 0) {
                    delete param.type_id
                }
                if (this.isFilt == 0) {
                    delete param.task_state
                }
                if (this.isFilt1 == 0) {
                    delete param.task_condition
                }
                this.$httpcompany.axiosGetBy(this.$api.taskList, param, res => {
                    if (res.code == 200) {
                        this.taskList = res.list;
                        this.adminPages.total = res.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getAllClass();
            },
            //查看报名列表
            changeToRegist(row) {
                this.$router.push(
                    {
                        path: "/company/taskcenter/registList",
                        query: {task_id: row.task_id}
                    })
            },
            changeToWorks(row) {
                this.$router.push(
                    {
                        path: "/company/taskcenter/worksList",
                        query: {task_id: row.task_id}
                    })
            },
            // 编辑
            editCompany(row) {
                let param = {
                    'task_id': row.task_id
                }
                this.$httpcompany.axiosGetBy(this.$api.taskEdit, param, (res) => {
                    if (res.code == 200) {
                        const resInfo = res.info
                        this.editForm.id = resInfo.task_id;
                        if (resInfo.task_type_id == 6) {
                            this.editForm.goods_id = resInfo.goods_id;
                        }
                        this.editForm.tasktype = resInfo.task_type_id;
                        this.editForm.title = resInfo.task_title;
                        this.editForm.describe = resInfo.task_content;
                        this.editForm.cover = resInfo.task_cover_file;
                        this.editForm.time = resInfo.task_end_time;
                        this.editForm.Islimit = resInfo.task_is_limit;
                        this.editForm.limitnum = resInfo.task_limit_num;
                        this.editForm.taskmoney = resInfo.task_money;
                        this.editForm.apply = resInfo.task_is_apply;
                        if (resInfo.task_file.length) {
                            for (let i = 0; i < resInfo.task_file.length; i++) {
                                delete resInfo.task_file[i].id;
                                delete resInfo.task_file[i].task_id;
                            }
                            this.editForm.file = resInfo.task_file;
                            this.editForm.file = JSON.parse(JSON.stringify(this.editForm.file).replace(/file_url/g, "file_path"))
                        }
                        this.$router.push({
                            path: "/company/taskcenter/publish",
                            query: {editForm: JSON.stringify(this.editForm)}
                        })
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                })
            },
            // 删除
            delCompany(row) {
                let that = this;
                const id = row.task_id;
                if (row.task_state == "进行中") {
                    this.$message({
                        message: '任务进行中无法删除',
                        type: 'warning'
                    });
                    return
                } else {
                    let param = {
                        task_id: id
                    };
                    this.$confirm("删除系统任务后不能恢复，确定要删除吗？", "删除任务", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        that.$httpcompany.axiosGetBy(that.$api.delTask, param, res => {
                            console.log(res);
                            if (res.code == 200) {
                                that.$message({
                                    type: "success",
                                    message: "删除成功"
                                });
                                that.getAllClass();
                            } else {
                                that.$message.warning(res.msg);
                            }
                        });
                    });
                }
            },
            //发布任务
            publishTask() {
                this.$router.push({path: "/company/taskcenter/publish"})
            }
        },
        watch: {
            isAll: function (val) {
                this.getAllClass();
            },
            isFilt: function (val) {
                this.getAllClass();
            },
            isFilt1: function (val) {
                this.getAllClass();
            }
        }

    }
</script>

<style scoped lang="scss">
    .activeClass {
        background: #4d5aff;
        color: #ffffff;
    }

    .company-contain {
        width: calc(100% - 280px);
        margin-bottom: 20px;
        margin-top: 20px;
        background-color: #ffffff;

        .table-box {
            width: 100%;
        }

    }
</style>
